exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-science-and-pipeline-js": () => import("./../../../src/pages/science-and-pipeline.js" /* webpackChunkName: "component---src-pages-science-and-pipeline-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-sanity-page-templates-news-index-js": () => import("./../../../src/sanity-page-templates/news/index.js" /* webpackChunkName: "component---src-sanity-page-templates-news-index-js" */),
  "component---src-sanity-page-templates-team-member-index-js": () => import("./../../../src/sanity-page-templates/team-member/index.js" /* webpackChunkName: "component---src-sanity-page-templates-team-member-index-js" */)
}

